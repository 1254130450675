/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import {ProductList} from '../ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript} from '../../../types/galleryTypes';
import s from './GalleryApp.scss';
import classNames from 'classnames';

import {Omit} from '@wix/native-components-infra/dist/es/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import 'wicg-inert';
import {SideFilters} from '../../../common/components/SideFilters/SideFilters';
import {Sort} from '../../../common/components/Sort/Sort';
import {MobileFiltersAndSort} from '../../../common/components/MobileFiltersAndSort/MobileFiltersAndSort';
import {
  announceIfFilterResultChanged,
  hasRelevantFilters,
  shouldRenderSortInLayout,
  shouldShowGalleryAppInLayout,
} from './appUtils';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {Experiments, GallerySlotIds} from '../../../constants';

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
  SideFiltersContainer = 'gallery-side-filters-container',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SideFilters = 'gallery-aside-filters',
  MobileContainer = 'mobile-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class GalleryAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.scrollToProductIfReturnedFromProductPage();
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    announceIfFilterResultChanged(prevProps, this.props, () =>
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      )
    );
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private readonly updateStateForScrollRestorationAndClearQueryParams = () => {
    // Thunderbolt's way to remove query params calls `locationSdkProvider.ts` which calls `history.replaceState` with `null`.
    // `scrollRestorationApi.ts` happens on loading of page and reads `history.state` which now is `null`. So it's logic is to scroll to top.
    // Fix: remove the query params natively, and call `history.replaceState` with the correct `scrollY` position.
    const urlObj = new URL(window.location.href);
    urlObj.searchParams.delete('scrollToProduct');
    window.history.replaceState({scrollY: window.scrollY}, '', urlObj.toString());
  };

  private scrollToProductIfReturnedFromProductPage() {
    const {
      globals: {scrollToProduct},
    } = this.props;

    if (scrollToProduct) {
      const productItem = document.querySelector(`[data-hook="${DataHook.Root}"] [data-slug="${scrollToProduct}"]`);
      if (productItem) {
        productItem.scrollIntoView({block: 'center'});
        this.updateStateForScrollRestorationAndClearQueryParams();
      }
    }
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  public render() {
    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }

    const {
      hasSelectedFilters,
      styles,
      stylesParams,
      filterModels,
      shouldShowMobile,
      shouldShowSort,
      isCategoryVisible,
      experiments: {shouldRenderSlotsInGallery},
      useExperiments,
    } = this.props.globals;
    const isFullWidth = styles.get(stylesParams.full_width);
    const responsive = styles.get(stylesParams.responsive);
    const isDesktop = !shouldShowMobile;

    const shouldRenderSideFilters = isDesktop && hasRelevantFilters(filterModels) && isCategoryVisible;
    const shouldRenderDesktopSort = isDesktop && shouldRenderSortInLayout(this.props.globals) && isCategoryVisible;
    const shouldRenderMobileFiltersAndSort = shouldShowMobile && isCategoryVisible;

    const shouldHaveStickySidebar = styles.get(stylesParams.gallery_enableStickySidebar);

    const classnames = {
      app: classNames({
        [s.galleryApp]: true,
        deviceMobile: shouldShowMobile,
        notCssPerBreakpoint: !this.props.host.usesCssPerBreakpoint,
      }),
      content: classNames({
        [s.content]: true,
        [s.responsiveWidth]: true,
        [s.contentResponsive]: responsive,
        [s.fullWidth]: isFullWidth,
      }),
      container: classNames(s.container, {
        [s.galleryContentWidth]: isDesktop && shouldRenderSideFilters,
      }),
      sideFiltersContainer: classNames({
        [s.filtersContainerWidth]: isDesktop && shouldRenderSideFilters,
        [s.sideFiltersWithSort]: isDesktop && shouldShowSort,
        [s.stickySidebar]: useExperiments.enabled(Experiments.AllowStickySidebarInViewer) && shouldHaveStickySidebar,
      }),
    };

    const isPluginInstalledInGalleryProductsTopSlot = !!this.props.globals.slots?.[GallerySlotIds.GalleryProductsTop];
    const isPluginInstalledInGalleryProductsBottomSlot =
      !!this.props.globals.slots?.[GallerySlotIds.GalleryProductsBottom];

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
          <div data-hook={DataHook.Content} className={classnames.content}>
            {shouldRenderSideFilters && (
              <div data-hook={DataHook.SideFiltersContainer} className={classnames.sideFiltersContainer}>
                <SideFilters {...this.props.globals} />
              </div>
            )}
            <div data-hook={DataHook.Container} className={classnames.container}>
              {shouldRenderDesktopSort && <Sort />}
              {shouldRenderMobileFiltersAndSort && <MobileFiltersAndSort {...this.props.globals} />}
              {shouldRenderSlotsInGallery && (
                <div className={classNames({[s.galleryProductsTopSlot]: isPluginInstalledInGalleryProductsTopSlot})}>
                  <SlotsPlaceholder slotId={GallerySlotIds.GalleryProductsTop} />
                </div>
              )}
              {<ProductList hasFilters={hasSelectedFilters} />}
              {shouldRenderSlotsInGallery && (
                <div
                  className={classNames({[s.galleryProductsBottomSlot]: isPluginInstalledInGalleryProductsBottomSlot})}>
                  <SlotsPlaceholder slotId={GallerySlotIds.GalleryProductsBottom} />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const GalleryApp = withGlobals(withTranslations()(GalleryAppComp));
